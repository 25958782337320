import React, { useState, useLayoutEffect } from "react";
import ReactMarkdown from "react-markdown";

import Info from "@templates/Info";
import ContentBox from "@atoms/ContentBox";
import Icon from "@atoms/Icon";
import Text from "@atoms/Text";

import { cookies } from "@content/cookies";
import CookiesSvg from "@images/icons/cookies.svg";

const CookiesPage = () => {
  const [startPage, setStartPage] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500);
  }, [setStartPage]);

  return (
    <Info active={startPage}>
      <ContentBox>
        <h2>
          <Icon src={CookiesSvg} /> Cookies
        </h2>
      </ContentBox>
      <ContentBox>
        <Text>
          <ReactMarkdown children={cookies.content} />
        </Text>
      </ContentBox>
    </Info>
  );
};

export default CookiesPage;
